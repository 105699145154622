import AWS from 'aws-sdk';

const config = {
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.Credentials(
    process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  ),
};

AWS.config.update(config);

export const s3 = new AWS.S3();
